<template>
  <span>
    <b-dropdown-item
      :to="item.to"
      link-class="d-flex align-items-center"
      @click="item.action"
    >
      <feather-icon
        size="16"
        :icon="item.icon"
        class="mr-50"
      />
      <span> {{ item.name }} </span>
    </b-dropdown-item>
    <b-dropdown-divider v-if="item.divider" />
  </span>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class UserDropdownItem extends Vue {
  @Prop() item
}
</script>